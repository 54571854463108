import React, { useEffect, useState } from 'react'
import FooterModal from './FooterModal'
import { useNavigate } from 'react-router-dom'
import { countriesData } from '../../Assets/Data/countriesDetails'
import countries from '../../Assets/Data/countries.json'

const CountryTile = ({
  data,
  name,
  value,
  score,
  otherCountryDetail,
  quality,
}) => {
  // console.log(otherCountryDetail)
  const renderText = (quality, data, country) => {
    let populatedData = ''
    switch (quality) {
      case 'Employment Rate':
        populatedData = `${data}%`
        break
      case 'University Rankings':
        populatedData = `${data} universities out Top 100 are in ${country}`
        break
      case 'Tuition Fees':
        populatedData = `Annual Average $${data} `
        break
      case 'Graduate Pay Scales':
        populatedData = `$${data} Per Annum`
        break
      case 'Cost of Living':
        populatedData = `$${data} Per Annum`
        break
      case 'PR and Settlement Opportunities':
        populatedData = `Rated ${data} out of 5`
        break
      case 'Spouse Work Rights':
        populatedData = `Rated ${data} out of 5`
        break
      case 'Cultural Fit':
        populatedData = `Rated ${data} out of 5`
        break
      case 'Safety and Security':
        populatedData = `Rated ${data} out of 5`
        break
      case 'Quality of Life':
        populatedData = `Rated ${data} out of 5`
        break
      default:
        populatedData = 'Invalid quality specified'
    }
    return populatedData
  }
  return (
    <div className="d-flex justify-content-start align-items-center countryTile_container">
      <span className="countryTile_tag">{value}</span>

      <h1 className="countryTile_h1">{name}</h1>
      {/* {Object.keys(data).map(
        (itm) =>
          itm.includes('_') && (
            <div className="p-2 w-100 text-left" key={itm}>
              <p style={{ whiteSpace: 'pre-wrap' }}>
                {itm} :{data[itm]}{' '}
              </p>
            </div>
          ),
      )} */}
      <p className="countryTile_score">
        {renderText(quality, data[quality], name)}
      </p>
    </div>
  )
}

const CountryPriroty = ({
  setShow,
  thumbData,
  setModals,
  setCountry,
  setThumbData,
  setScript,
}) => {
  const navigate = useNavigate()
  const [showResult, setShowResult] = useState(false)
  const [formData, setFormData] = useState([
    { name: 'Employment Rate' },
    { name: 'University Rankings' },
    { name: 'Tuition Fees' },
    { name: 'PR and Settlement Opportunities' },
    { name: 'Graduate Pay Scales' },
    { name: 'Spouse Work Rights' },
    { name: 'Cost of Living' },
    { name: 'Cultural Fit' },
    { name: 'Safety and Security' },
    { name: 'Quality of Life' },
  ])
  const [quality, setQuality] = useState('')

  useEffect(() => {
    if (showResult) {
      let country = countriesData
        .sort((a, b) => b[quality] - a[quality])
        .slice(0, 3)
      if (quality=='Tuition Fees' || quality == 'Cost of Living') {
        country = countriesData
        .sort((a, b) => a[quality] - b[quality])
        .slice(0, 3)
      }
      country = country?.map((itm) => itm.Country)
      let country_arr = countries.filter((item) => {
        if (country.includes(item.name)) {
          item[quality] = countriesData.find((cd) => cd.Country == item.name)[
            'Data_' + quality.split(' ').join('_')
          ]
          item['value'] = countriesData.find((cd) => cd.Country == item.name)[
            quality
          ]
          return item
        }
      })
      country_arr = country_arr.sort((a, b) => b['value'] - a['value'])
      if (quality=='Tuition Fees' || quality == 'Cost of Living') { 
        country_arr = country_arr.sort((a, b) => a['value'] - b['value'])
      }
      setThumbData(country_arr)
      setScript(country_arr[0])
    }
  }, [quality])

  return (
    <>
      <div className="modal-body">
        <div className="d-flex justify-content-between">
          <p className="modal-subheading">Select your priority</p>
        </div>
        <div
          className="d-flex justify-content-center  align-items-center w-100"
          style={{ gap: '1rem' }}
        >
          <select
            className="form-select"
            onChange={(e) => {
              setShowResult(true)
              setQuality(e.target.value)
              if (e.target.value == '') {
                setShowResult(false);
                setThumbData([])
              }
            }}
          >
            <option value="">Please Select</option>
            {formData?.map((itm) => (
              <option value={itm?.name}>{itm?.name}</option>
            ))}
          </select>
        </div>
      </div>
      {showResult && (
        <div className="px-3 pb-3">
          <h1
            className="sortTile_h1 text-center"
            style={{ fontWeight: '600', color: '#000' }}
          >
            Top 3 Results{' '}
          </h1>
          <br />
          <br />
          <div
            className="d-flex flex-row justify-content-center result-container"
            style={{ gap: '1rem' }}
          >
            {thumbData?.map((item, index) => (
              <CountryTile
                data={item}
                name={item.name}
                value={index + 1}
                quality={quality}
              />
            ))}
          </div>
        </div>
      )}

      <FooterModal
        navigate={navigate}
        nextElement={
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn"
              onClick={() => {
                setShow(false)
                setCountry(false)
                setThumbData([])
                setModals({
                  countryMain: false,
                  needHelpCountry: false,
                  needHelpCourrse: false,
                  countryScore: false,
                  courseMain: true,
                })
              }}
            >
              Skip to Course Counseling
            </button>
            &nbsp; &nbsp; &nbsp;
            <button
              type="button"
              className="btn btn-primary-regular"
              disabled={quality === '' ? true : false}
              onClick={() => {
                setModals({
                  countryMain: false,
                  needHelpCountry: false,
                  courseMain: false,
                  needHelpCourrse: false,
                })
                setShow(false)
              }}
            >
              Start Counselling
            </button>
          </div>
        }
      />
    </>
  )
}

export default CountryPriroty
