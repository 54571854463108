import React, { useEffect, useState } from 'react'

const PastEdu = (props) => {

    const [data, setData] = useState({
        gapYear: "",
        hsc: {
            name: "",
            start: "",
            end: "",
            grade: "",
            gradingSystem: "",
            schoolAddress: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
            degree: "",
            language: "",
            status: "",
            gradDate: "",
            board: ""
        },
        ssc: {
            name: "",
            start: "",
            end: "",
            grade: "",
            gradingSystem: "",
            schoolAddress: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
            degree: "",
            language: "",
            status: "",
            gradDate: "",
            board: ""
        },
        grad: {
            name: "",
            courseSpcl: "",
            univName: "",
            collegeName: "",
            cgpaBacklog: "",
            duration: "",
            schoolAddress: "",
            cityState: "",
            zipCode: "",
            country: "",
            degree: "",
            language: "",
            status: "",
            gradDate: "",
        },
        postGrad: {
            name: "",
            courseSpcl: "",
            univName: "",
            collegeName: "",
            cgpaBacklog: "",
            duration: "",
            schoolAddress: "",
            cityState: "",
            zipCode: "",
            country: "",
            degree: "",
            language: "",
            status: "",
            gradDate: "",
        },
    })

    const onChange = (e) => {
        const target = e.target.name.split(".");

        if (target.length === 2) {
            setData({
                ...data,
                [target[0]]: {
                    ...data[target[0]],
                    [target[1]]: e.target.value
                }
            })
        }
        else {
            setData({ ...data, [e.target.name]: e.target.value })
        }
    }

    useEffect(() => {
        if (props.data !== null && props.data !== undefined) {
            setData(props.data)
        }
    }, [props.data])

    return (
        <div className="tab-pane fade" id="v-pills-education" role="tabpanel" aria-labelledby="v-pills-education-tab">
            <div className="row profile-section">
                <div className="col">
                    <div className="section-head">Past Education</div>
                    {/* <div className="row">
                <div className="col">
                    <a href="" className="edit-section">
                        <i className="fa-solid fa-lg fa-pen-to-square" />
                    </a>
                    <ul className="profile-block">
                        <li>Master of Science in Information Technology</li>
                        <li>Computer Enginerring</li>
                        <li>
                            <svg width={14} height={17} viewBox="0 0 14 17" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13 7C13 11.6667 7 15.6667 7 15.6667C7 15.6667 1 11.6667 1 7C1 5.4087 1.63214 3.88258 2.75736 2.75736C3.88258 1.63214 5.4087 1 7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7Z"
                                    stroke="#98A2B3" strokeWidth="1.33333" strokeLinecap="round"
                                    strokeLinejoin="round" />
                                <path
                                    d="M7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9Z"
                                    stroke="#98A2B3" strokeWidth="1.33333" strokeLinecap="round"
                                    strokeLinejoin="round" />
                            </svg>
                            India
                        </li>
                        <li>Sikkim Manipal University</li>
                        <li>
                            <svg width={20} height={22} viewBox="0 0 20 22" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14 1V5M6 1V5M1 9H19M3 3H17C18.1046 3 19 3.89543 19 5V19C19 20.1046 18.1046 21 17 21H3C1.89543 21 1 20.1046 1 19V5C1 3.89543 1.89543 3 3 3Z"
                                    stroke="#98A2B3" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span>08/2020</span> - <span>08/2022</span>
                        </li>
                    </ul>
                </div>
            </div> */}
                    <div className="form-group edit-form section-content">
                        <div className="row">
                            <div className="col">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingOne">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                                                aria-expanded="false" aria-controls="flush-collapseOne">
                                                10th / HSC
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" className="form-control" defaultValue=""
                                                                name="hsc.name" onChange={onChange} value={data.hsc.name}
                                                                placeholder="Name of the Institution" aria-label="" />
                                                            <label htmlFor="">
                                                                Name of the Institution
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="date"  onChange={onChange} className="form-control" defaultValue=""
                                                                name="hsc.start" value={data.hsc.start}
                                                                placeholder="Start Date" aria-label="" />
                                                            <label htmlFor="">Start Date</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="date" onChange={onChange} className="form-control" defaultValue=""
                                                                name="hsc.end" value={data.hsc.end}
                                                                placeholder="End Date" aria-label="" min={
                                                                    !isNaN(new Date(data.hsc.start))
                                                                      ? new Date(data.hsc.start)
                                                                          .toISOString()
                                                                          ?.split("T")[0]
                                                                      : new Date().toISOString().split("T")[0]
                                                                  }/>
                                                            <label htmlFor="">End Date</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="hsc.grade" value={data.hsc.grade}
                                                                placeholder="Grades / % / Score" aria-label="" />
                                                            <label htmlFor="">Grades / % / Score</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="hsc.gradingSystem" value={data.hsc.gradingSystem}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">Grading System</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="hsc.schoolAddress" value={data.hsc.schoolAddress}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">School Address</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="hsc.city" value={data.hsc.city}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">City of Study</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="hsc.state" value={data.hsc.state}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">State of Study</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="hsc.zipCode" value={data.hsc.zipCode}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">Zip Code</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="hsc.country" value={data.hsc.country}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">Country of Study</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="hsc.board" value={data.hsc.board}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">
                                                                Board
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="hsc.degree" value={data.hsc.degree}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">
                                                                Qualification/Degree Achieved
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="hsc.language" value={data.hsc.language}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">
                                                                Primary Language of Instruction
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="hsc.status" value={data.hsc.status}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">
                                                                Status (Studying/Graduated)
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="date" onChange={onChange} className="form-control" defaultValue=""
                                                                name="hsc.gradDate" value={data.hsc.gradDate}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">Graduation Date</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingTwo">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                                                aria-expanded="false" aria-controls="flush-collapseTwo">
                                                12th / SSC / Technical Diploma / IB &nbsp;&nbsp;&nbsp; &nbsp;
                      <span className="d-flex justify-content-center align-items-center">
                        {" "}
                        <input
                          type="checkbox"
                          checked={data?.ssc?.dna}
                          onChange={(e) =>
                            setData({
                              ...data,
                              ssc: {...data?.ssc, dna: e.target.checked},
                            })
                          }
                        />{" "}
                        &nbsp; Does not Apply
                      </span>
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwo" className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="ssc.name" value={data.ssc.name}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">
                                                                Name of the Institution
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="date" onChange={onChange} className="form-control" defaultValue=""
                                                                name="ssc.start" value={data.ssc.start}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">Start Date</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="date" onChange={onChange} className="form-control" defaultValue=""
                                                                name="ssc.end" value={data.ssc.end}
                                                                placeholder="" aria-label="" min={
                                                                    !isNaN(new Date(data.ssc.start))
                                                                      ? new Date(data.ssc.start)
                                                                          .toISOString()
                                                                          ?.split("T")[0]
                                                                      : new Date().toISOString().split("T")[0]
                                                                  } />
                                                            <label htmlFor="">End Date</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="ssc.grade" value={data.ssc.grade}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">Grades / % / Score</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                        <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="ssc.gradingSystem" value={data.ssc.gradingSystem}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">Grading System</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="ssc.schoolAddress" value={data.ssc.schoolAddress}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">School Address</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="ssc.city" value={data.ssc.city}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">City of Study</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="ssc.state" value={data.ssc.state}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">State of Study</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="ssc.zipCode" value={data.ssc.zipCode}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">Zip Code</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="ssc.country" value={data.ssc.country}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">Country of Study</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="ssc.board" value={data.ssc.board}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">
                                                                Board
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="ssc.degree" value={data.ssc.degree}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">
                                                                Qualification/Degree Achieved
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="ssc.language" value={data.ssc.language}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">
                                                                Primary Language of Instruction
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="ssc.status" value={data.ssc.status}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">
                                                                Status (Studying/Graduated)
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="date" onChange={onChange} className="form-control" defaultValue=""
                                                                name="ssc.gradDate" value={data.ssc.gradDate}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">Graduation Date</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingThree">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#flush-collapseThree"
                                                aria-expanded="false" aria-controls="flush-collapseThree">
                                                Graduation &nbsp;&nbsp;&nbsp; &nbsp;
                      <span className="d-flex justify-content-center align-items-center">
                        {" "}
                        <input
                          type="checkbox"
                          checked={data?.grad?.dna}
                          onChange={(e) =>
                            setData({
                              ...data,
                              grad: {...data?.grad, dna: e.target.checked},
                            })
                          }
                        />{" "}
                        &nbsp; Does not Apply
                      </span>
                                            </button>
                                        </h2>
                                        <div id="flush-collapseThree" className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <select name="grad.name" onChange={onChange} value={data.grad.name}
                                                                className="form-select" placeholder="Select Degree">
                                                                <option value="Select Degree">Select Degree</option>
                                                                <option value="Bachelor of Arts (B.A.)">Bachelor of Arts (B.A.)
                                                                </option>
                                                                <option value="Bachelor of Science (B.S.)">Bachelor of Science
                                                                    (B.S.)
                                                                </option>
                                                                <option value="Bachelor of Engineering
                                                            (B.Eng.)">Bachelor of Engineering
                                                                    (B.Eng.)</option>
                                                                <option value="Bachelor of Technology
                                                            (B.Tech)">Bachelor of Technology
                                                                    (B.Tech)</option>
                                                                <option value="Bachelor of Business
                                                            Administration (BBA)">Bachelor of Business
                                                                    Administration (BBA)</option>
                                                                <option value="Bachelor of Fine Arts (BFA)">Bachelor of Fine
                                                                    Arts (BFA)
                                                                </option>
                                                                <option value="Bachelor of Computer
                                                            Science (B.Comp.Sc. or BCS)">Bachelor of Computer
                                                                    Science (B.Comp.Sc. or BCS)</option>
                                                                <option value="Bachelor of Medicine,
                                                            Bachelor of Surgery (MBBS)">Bachelor of Medicine,
                                                                    Bachelor of Surgery (MBBS)</option>
                                                                <option value="Bachelor of Education
                                                            (B.Ed.)">Bachelor of Education
                                                                    (B.Ed.)</option>
                                                                <option value="Bachelor of Social Work
                                                            (BSW)">Bachelor of Social Work
                                                                    (BSW)</option>
                                                                <option value="Bachelor of Commerce
                                                            (B.Com)">Bachelor of Commerce
                                                                    (B.Com)</option>
                                                                <option value="Bachelor of Nursing (B.N.)">Bachelor of Nursing
                                                                    (B.N.)
                                                                </option>
                                                                <option value="Bachelor of Architecture
                                                            (B.Arch)">Bachelor of Architecture
                                                                    (B.Arch)</option>
                                                                <option value="Bachelor of Music (B.Mus.)">Bachelor of Music
                                                                    (B.Mus.)
                                                                </option>
                                                                <option value="Bachelor of Laws (LL.B)">Bachelor of Laws (LL.B)
                                                                </option>
                                                                <option value="Bachelor of Pharmacy
                                                            (B.Pharm)">Bachelor of Pharmacy
                                                                    (B.Pharm)</option>
                                                                <option value="Bachelor of Dental Surgery
                                                            (BDS)">Bachelor of Dental Surgery
                                                                    (BDS)</option>
                                                                <option value="Bachelor of Science in
                                                            Nursing (BSN)">Bachelor of Science in
                                                                    Nursing (BSN)</option>
                                                                <option value="Bachelor of Fine Arts (BFA)">Bachelor of Fine
                                                                    Arts (BFA)
                                                                </option>
                                                                <option value="Bachelor of Economics
                                                            (B.Econ)">Bachelor of Economics
                                                                    (B.Econ)</option>
                                                                <option value="Bachelor of Communication
                                                            (B.Comm)">Bachelor of Communication
                                                                    (B.Comm)</option>
                                                                <option value="Bachelor of Environmental
                                                            Science (B.Env.Sc)">Bachelor of Environmental
                                                                    Science (B.Env.Sc)</option>
                                                                <option value="Bachelor of Design (B.Des)">Bachelor of Design
                                                                    (B.Des)
                                                                </option>
                                                            </select>
                                                            <label htmlFor="">
                                                                Degree
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="grad.courseSpcl" value={data.grad.courseSpcl}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">
                                                                Course / Specialization (if any)
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="grad.univName" value={data.grad.univName}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">University Name</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="grad.collegeName" value={data.grad.collegeName}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">
                                                                College Name
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="grad.cgpaBacklog" value={data.grad.cgpaBacklog}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">
                                                                CGPA / % / Backlogs (if any)
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="grad.duration" value={data.grad.duration}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">Duration</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="grad.schoolAddress" value={data.grad.schoolAddress}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">School Address</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="grad.cityState" value={data.grad.cityState}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">City, State of Study</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="grad.zipCode" value={data.grad.zipCode}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">Zip Code</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="grad.country" value={data.grad.country}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">Country of Study</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="grad.degree" value={data.grad.degree}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">
                                                                Qualification/Degree Achieved
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="grad.language" value={data.grad.language}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">
                                                                Primary Language of Instruction
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue=""
                                                                name="grad.status" value={data.grad.status}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">
                                                                Status (Studying/Graduated)
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="date" onChange={onChange} className="form-control" defaultValue=""
                                                                name="grad.gradDate" value={data.grad.gradDate}
                                                                placeholder="" aria-label="" />
                                                            <label htmlFor="">Graduation Date</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingFour">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#flush-collapseFour"
                                                aria-expanded="false" aria-controls="flush-collapseFour">
                                                Post Graduation &nbsp;&nbsp;&nbsp; &nbsp;
                      <span className="d-flex justify-content-center align-items-center">
                        {" "}
                        <input
                          type="checkbox"
                          checked={data?.postGrad?.dna}
                          onChange={(e) =>
                            setData({
                              ...data,
                              postGrad: {...data?.postGrad, dna: e.target.checked},
                            })
                          }
                        />{" "}
                        &nbsp; Does not Apply
                      </span>
                                            </button>
                                        </h2>
                                        <div id="flush-collapseFour" className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <select name="postGrad.name" onChange={onChange} value={data.postGrad.name}
                                                                className="form-select"
                                                                placeholder="Select Degree">
                                                                <option value="Select Degree">Select Degree</option>
                                                                <option value="Master of Arts (M.A.)">Master of Arts (M.A.)
                                                                </option>
                                                                <option value="Master of Science (M.S.)">Master of Science (M.S.)
                                                                </option>
                                                                <option value="Master of Business
                                                                    Administration (MBA)">Master of Business
                                                                    Administration (MBA)</option>
                                                                <option value="Master of Engineering
                                                                    (M.Eng.)">Master of Engineering
                                                                    (M.Eng.)</option>
                                                                <option value="Master of Technology
                                                                    (M.Tech)">Master of Technology
                                                                    (M.Tech)</option>
                                                                <option value="Master of Fine Arts (MFA)">Master of Fine Arts (MFA)
                                                                </option>
                                                                <option value="Master of Computer Science
                                                                    (M.Comp.Sc. or MCS)">Master of Computer Science
                                                                    (M.Comp.Sc. or MCS)</option>
                                                                <option value="Master of Education (M.Ed.)">Master of Education (M.Ed.)
                                                                </option>
                                                                <option value="Master of Social Work (MSW)">Master of Social Work (MSW)
                                                                </option>
                                                                <option value="Master of Commerce (M.Com)">Master of Commerce (M.Com)
                                                                </option>
                                                                <option value="Master of Nursing (M.N.)">Master of Nursing (M.N.)
                                                                </option>
                                                                <option value="Master of Architecture
                                                                    (M.Arch)">Master of Architecture
                                                                    (M.Arch)</option>
                                                                <option value="Master of Music (M.Mus.)">Master of Music (M.Mus.)
                                                                </option>
                                                                <option value="Master of Laws (LL.M)">Master of Laws (LL.M)
                                                                </option>
                                                                <option value="Master of Pharmacy
                                                                    (M.Pharm)">Master of Pharmacy
                                                                    (M.Pharm)</option>
                                                                <option value="Master of Dental Surgery
                                                                    (MDS)">Master of Dental Surgery
                                                                    (MDS)</option>
                                                                <option value="Master of Science in
                                                                    Nursing (MSN)">Master of Science in
                                                                    Nursing (MSN)</option>
                                                                <option value="Master of Fine Arts (MFA)">Master of Fine Arts (MFA)
                                                                </option>
                                                                <option value="Master of Economics
                                                                    (M.Econ)">Master of Economics
                                                                    (M.Econ)</option>
                                                                <option value="Master of Communication
                                                                    (M.Comm)">Master of Communication
                                                                    (M.Comm)</option>
                                                                <option value="Master of Environmental
                                                                    Science (M.Env.Sc)">Master of Environmental
                                                                    Science (M.Env.Sc)</option>
                                                                <option value="Master of Design (M.Des)">Master of Design (M.Des)
                                                                </option>
                                                                <option value="Master of Public
                                                                    Administration (MPA)">Master of Public
                                                                    Administration (MPA)</option>
                                                                <option value="Master of Public Health
                                                                    (MPH)">Master of Public Health
                                                                    (MPH)</option>
                                                                <option value="Master of International
                                                                    Business (MIB)">Master of International
                                                                    Business (MIB)</option>
                                                            </select>
                                                            <label htmlFor="">
                                                                Degree
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue="" name="postGrad.courseSpcl"
                                                                value={data.postGrad.courseSpcl} placeholder=""
                                                                aria-label="" />
                                                            <label htmlFor="">
                                                                Course / Specialization (if any)
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue="" name="postGrad.univName"
                                                                value={data.postGrad.univName} placeholder=""
                                                                aria-label="" />
                                                            <label htmlFor="">University Name</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue="" name="postGrad.collegeName"
                                                                value={data.postGrad.collegeName} placeholder=""
                                                                aria-label="" />
                                                            <label htmlFor="">
                                                                College Name
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue="" name="postGrad.cgpaBacklog"
                                                                value={data.postGrad.cgpaBacklog} placeholder=""
                                                                aria-label="" />
                                                            <label htmlFor="">CGPA / % / Backlogs (if any)</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue="" name="postGrad.duration"
                                                                value={data.postGrad.duration} placeholder=""
                                                                aria-label="" />
                                                            <label htmlFor="">Duration</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue="" name="postGrad.schoolAddress"
                                                                value={data.postGrad.schoolAddress} placeholder=""
                                                                aria-label="" />
                                                            <label htmlFor="">School Address</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue="" name="postGrad.cityState"
                                                                value={data.postGrad.cityState} placeholder=""
                                                                aria-label="" />
                                                            <label htmlFor="">City, State of Study</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue="" name="postGrad.zipCode"
                                                                value={data.postGrad.zipCode} placeholder=""
                                                                aria-label="" />
                                                            <label htmlFor="">Zip Code</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue="" name="postGrad.country"
                                                                value={data.postGrad.country} placeholder=""
                                                                aria-label="" />
                                                            <label htmlFor="">Country of Study</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue="" name="postGrad.degree"
                                                                value={data.postGrad.degree} placeholder=""
                                                                aria-label="" />
                                                            <label htmlFor="">
                                                                Qualification/Degree Achieved
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue="" name="postGrad.language"
                                                                value={data.postGrad.language} placeholder=""
                                                                aria-label="" />
                                                            <label htmlFor="">
                                                                Primary Language of Instruction
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="text" onChange={onChange} className="form-control" defaultValue="" name="postGrad.status"
                                                                value={data.postGrad.status} placeholder=""
                                                                aria-label="" />
                                                            <label htmlFor="">
                                                                Status (Studying/Graduated)
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-floating mb-3">
                                                            <input type="date" onChange={onChange} className="form-control" defaultValue="" name="postGrad.gradDate"
                                                                value={data.postGrad.gradDate} placeholder=""
                                                                aria-label="" />
                                                            <label htmlFor="">Graduation Date</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-floating mb-3 mt-4">
                                    <input type="text" className="form-control" onChange={onChange} defaultValue="" name="gapYear"
                                        value={data.gapYear} placeholder="" aria-label="" />
                                    <label htmlFor="">Gap Year (if any)</label>
                                </div>
                            </div>
                        </div>
                        <div className="row responsive-col">
                            <div className="col text-end action-btn-group">
                                <button type="submit" className="btn btn-secondary">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary-regular" onClick={() => { props.updateData("education", data) }}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PastEdu