import React, { useEffect, useState } from 'react'
import WorkExpCard from './WorkExpCard';

const WorkExp = (props) => {
    const [work, setWork] = useState([]);
    const [data, setData] = useState({ skip: false });

    const [newWorkExp, setNewWorkExp] = useState({ company: "", designation: "", start: "", end: "", type: "", isRecent: false })
    const [edtWorkExp, setEditWorkExp] = useState(false);
    const [error, setError] = useState("");
    const addWorkExp = async () => {
        let newdata = work;
        if (newWorkExp.end < newWorkExp.start) {
            setError("End date cannot be less than start date");
            return;
          }
        if (newWorkExp.isRecent) {
            newdata = await newdata.map((item, i) => {
                return { ...item, isRecent: false };
            });
        }

        newdata.push(newWorkExp);
        setWork(newdata)
        setNewWorkExp({ company: "", designation: "", start: "", end: "", type: "", isRecent: false })
        setEditWorkExp(false);
    }

    const onChange = (event) => {
        const { name, value } = event.target;
        if ((event.target.name === 'start' || event.target.name === 'end') && (newWorkExp[event.target.name].length === 1 && event.target.value.length === 2)) {
            setNewWorkExp({ ...newWorkExp, [event.target.name]: String(event.target.value + '/') });
            return;
        }
        if (name === "end" && value < newWorkExp.start) {
            setError("End date cannot be less than start date");
          } else {
            setError("");
          }
        setNewWorkExp({ ...newWorkExp, [event.target.name]: event.target.value });
    };

    useEffect(() => {
        if (work.length === 0) {
            setEditWorkExp(true)
        } else {
            setEditWorkExp(false)
        }
        if(typeof(props?.workDna) == 'boolean'){
            setData({skip: props?.workDna})
          }
    }, [work])

    useEffect(() => {
        if (props.data !== null && props.data !== undefined) {
            setWork(props.data)
        }
    }, [props.data])

    return (
        <div className="tab-pane fade" id="v-pills-work" role="tabpanel" aria-labelledby="v-pills-work-tab">
            <div className="row profile-section">
                <div className="col">
                    <div className="section-head d-flex flex-row justify-content-between">
                    <div className="d-flex">Work Experience &nbsp;&nbsp;&nbsp; &nbsp;<span className="d-flex justify-content-center align-items-center">
              {" "}
              <input
                type="checkbox"
                // disabled={data?.skip}
                checked={data?.skip}
                onChange={(e) =>
                  setData({
                    ...data,
                    skip: e.target.checked,
                  })
                }
              />{" "}
              &nbsp; Does not Apply
            </span></div>
                        {work.length > 0 ? <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" onClick={() => { setEditWorkExp(true) }}
                            className="bi bi-plus-lg edit-btn" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z">
                            </path>
                        </svg> : ""}
                    </div>
                    <div className="row">
                        <div className="col">
                            {work.map((item, idx) => (
                                <WorkExpCard setWork={setWork} exp={item} key={idx} work={work} idx={idx} />
                            ))}

                        </div>
                    </div>

                    <div className="form-group edit-form section-content">
                        {edtWorkExp ? <>
                            <div className="row mb-3">
                                <div className="col">
                                    <label htmlFor="">
                                        Job Type<span className="required">*</span>
                                    </label>
                                    <br />
                                    <input type="radio" className="btn-check" name="options61" id="options61"
                                        autoComplete="off" defaultChecked="" checked={newWorkExp.type === "Full Time"} onClick={() => { setNewWorkExp({ ...newWorkExp, type: "Full Time" }) }} />
                                    <label className="btn btn-secondary" htmlFor="options61">
                                        <i className="fa-solid fa-briefcase" /> Full Time
                                    </label>
                                    <input type="radio" className="btn-check" name="options62" id="options62"
                                        autoComplete="off" checked={newWorkExp.type === "Part Time"} onClick={() => { setNewWorkExp({ ...newWorkExp, type: "Part Time" }) }} />
                                    <label className="btn btn-secondary" htmlFor="options62">
                                        <i className="fa-solid fa-briefcase" /> Part Time
                                    </label>
                                    <input type="radio" className="btn-check" name="options63" id="options63"
                                        autoComplete="off" checked={newWorkExp.type === "Internship"} onClick={() => { setNewWorkExp({ ...newWorkExp, type: "Internship" }) }} />
                                    <label className="btn btn-secondary" htmlFor="options63">
                                        <i className="fa-solid fa-briefcase" /> Internship
                                    </label>
                                </div>
                                <div className="col">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control"
                                            placeholder="Highest Education" defaultValue=""
                                            aria-label="Highest Education" value={newWorkExp.company} onChange={onChange} name='company' />
                                        <label htmlFor="">Last/Current Company</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" placeholder="Field of Study"
                                            aria-label="Field of Study" value={newWorkExp.designation} onChange={onChange} name='designation' />
                                        <label htmlFor="">Designation</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-floating mb-3">
                                        <input type="month" className="form-control"
                                            placeholder="Start Month/Year" aria-label="Start Month/Year" value={newWorkExp.start} onChange={onChange} name='start' />
                                        <label htmlFor="">Start Month/Year</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-floating mb-3">
                                        {error && (
                                            <div className="text-error text-end">{error}</div>
                                        )}
                                        <input type="month" className={`form-control ${error && "border-error"}`}
                                            placeholder="End Month/Year" aria-label="End Month/Year" value={newWorkExp.end} onChange={onChange} name='end' />
                                        <label htmlFor="">End Month/Year</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row responsive-col">
                                <div className="col">
                                    <button type="submit" className="btn btn-primary-regular" onClick={() => {
                                        setNewWorkExp({ ...newWorkExp, isRecent: true });
                                    }}>
                                        Mark as most recent
                                    </button>
                                </div>
                                <div className="col text-end action-btn-group">
                                    <button type="submit" className="btn btn-secondary" onClick={() => {
                                        setEditWorkExp(false)
                                        setNewWorkExp({ company: "", designation: "", start: "", end: "", type: "", isRecent: false });
                                    }} disabled={work.length === 0}>
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary-regular" onClick={addWorkExp}>
                                        Add
                                    </button>
                                </div>
                            </div>
                        </> : ""}
                        <button type="submit" className="btn btn-primary-regular ms-1 mt-3" onClick={() => { props.updateData("workexp", work, data?.skip) }}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkExp
