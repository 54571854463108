import React, { useState } from "react";
import subscription from "../Assets/Data/subscription.json";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "./Checkout";
import API from "../../Api/Api";
import { loadStripe } from "@stripe/stripe-js";
import swal from "sweetalert";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PurchaseCredits = () => {
  const [orderData, setOrderData] = useState("");
  const navigate = useNavigate();
  const [currentPlan, setCurrentPlan] = useState({});
  const [paymentScreen, setPaymentScreen] = useState(false);

  const purchasePlan = async (id) => {
    try {
      const data = await API(
        "post",
        "/subscription/user/purchase-subscription",
        {
          id: id,
        }
      );
      setOrderData(data?.data?.clientSecret);
      setPaymentScreen(true);
    } catch (error) {}
  };

  const refill = async (planid) => {
    try {
      await API("post", `/subscription/user/purchase-subscription/success`, {
        studentid: localStorage.getItem("_id"),
        subscriptionid: planid,
        paymentid: "free_plan_0_usd",
      });
      swal({
        title: "Subscription successfully!",
        text: "Subscription successfull, you will be redirected to dashboard and your subscription will be active.",
        icon: "success",
      }).then((res) => {
        if (res) {
          navigate("/userdashboard");
        }
      });
    } catch (error) {}
  };

  if (paymentScreen) {
    return (
      <div>
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: orderData,
          }}
        >
          {" "}
          <CheckoutForm itm={currentPlan} />
        </Elements>
      </div>
    );
  }

  return (
    <>
      <main id="content" className="mentor-insidepage">
        <section
          id="pricing"
          className="secondary-color text-center scrollto clearfix"
        >
          <div className="">
            <div className="mentor-section-heading">
              <h3>YOUR CHOICE</h3>
              <h2 className="mentor-section-title">
                We have the Subscription for you
              </h2>
            </div>
            <div className="subscription-plan">
              {subscription?.map((itm) => (
                <div
                  className="pricing-block mentor-col-3 wow fadeInUp"
                  data-wow-delay="0.4s"
                  key={itm?.id}
                >
                  <div className="pricing-block-content">
                    <h3>{itm?.name}</h3>
                    <p className="pricing-sub">{itm?.tag}</p>
                    <div className="price">
                        <span>$</span>
                        {itm?.price}
                      </div>
                    <div className="pricing">
                      <p>{itm?.description}</p>
                    </div>
                    <ul>
                      {itm?.benefits?.map((feature) => (
                        <li>
                          <strong>{feature?.name}</strong>
                          <p>{feature?.desc}</p>
                        </li>
                      ))}
                    </ul>
                    <button
                      className="mentor-button-sm"
                      onClick={() => {
                        if (itm?.price == 0) {
                          refill(itm?.id);
                        } else {
                          purchasePlan(itm?.id);
                          setCurrentPlan(itm);
                        }
                      }}
                    >
                      Subscribe Now
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default PurchaseCredits;
