import React, { useEffect, useState } from 'react'
import API from '../../Api/Api'
import swal from 'sweetalert'
import Select from 'react-select'
import countryCodes from '../Assets/Data/countries.json'
import country_codes from '../Assets/Data/countries_code.json'
import { validateProfileForm } from '../../utils/validation';

export const ProfileForm = () => {
    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        dateofbirth: '',
        countryCode: '',
        phone: '',
        countryofinterest: [],
        courseofinterest: '',
        level: '',
        spcialization: '',
        hscyearpassing: '',
        hscgrade: '',
        sscyearofpassing: '',
        sscgrade: '',
        gradcourse: '',
        graduniversity: '',
        gradcgpa: '',
        gradduration: '',
        postgradcourse: '',
        postgraduniversity: '',
        postgradcgpa: '',
        postgradduration: '',
        ieltstoefl: '',
        gregmat: '',
        internshipcompany: '',
        internshipduration: '',
        internshipproject: '',
        workexpcompany: '',
        workexpjobtitle: '',
        workexpduration: ''
    })
    // State to manage individual IELTS/TOEFL scores
    const [ieltstoeflScores, setIeltsToeflScores] = useState({
        overall: '',
        listening: '',
        reading: '',
        writing: '',
        speaking: ''
    });

    // State to manage individual GRE/GMAT scores
    const [gregmatScores, setGregmatScores] = useState({
        overall: '',
        quantitative: '',
        verbal: '',
        awa: ''
    });
    const [loading, setLoading] = useState(false)
    const onChangeInput = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[e.target.name]; // Use 'e' instead of 'event'
            return newErrors;
          });
    }
    const handleIeltsToeflChange = (e) => {
        setIeltsToeflScores({ ...ieltstoeflScores, [e.target.name]: e.target.value });
    };

    const handleGregmatChange = (e) => {
        setGregmatScores({ ...gregmatScores, [e.target.name]: e.target.value });
    };

    const [errors, setErrors] = useState({});

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const { isValid, errors } = validateProfileForm(data);

        //console.log('Validation Result:', { isValid, errors });

            if (!isValid) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            setErrors(errors); // Set validation errors to state
            setLoading(false);
            return; // Exit the function if form is not valid
            }
        setLoading(true)
        
        // Combine IELTS/TOEFL scores into a single string
        const combinedIeltsToefl = `${ieltstoeflScores.overall} | ${ieltstoeflScores.listening} | ${ieltstoeflScores.reading} | ${ieltstoeflScores.writing} | ${ieltstoeflScores.speaking}`;

        // Combine GRE/GMAT scores into a single string
        const combinedGregmat = `${gregmatScores.overall} | ${gregmatScores.quantitative} | ${gregmatScores.verbal} | ${gregmatScores.awa}`;

        // Update the data state with the combined scores
        const updatedData = {
        ...data,
        ieltstoefl: combinedIeltsToefl,
        gregmat: combinedGregmat,
        };

        try {
            e.preventDefault()
            await API('post', `/profile`, updatedData)
            swal({
                title: 'Form Submitted!',
                text: `Schedule your free counseling session - check your email for details.`,
                icon: 'success',
                button: 'Ok!',
            })
            setData({
                firstName: '',
                lastName: '',
                email: '',
                dateofbirth: '',
                countryCode: '',
                phone: '',
                countryofinterest: [],
                courseofinterest: '',
                level: '',
                spcialization: '',
                hscyearpassing: '',
                hscgrade: '',
                sscyearofpassing: '',
                sscgrade: '',
                gradcourse: '',
                graduniversity: '',
                gradcgpa: '',
                gradduration: '',
                postgradcourse: '',
                postgraduniversity: '',
                postgradcgpa: '',
                postgradduration: '',
                ieltstoefl: '',
                gregmat: '',
                internshipcompany: '',
                internshipduration: '',
                internshipproject: '',
                workexpcompany: '',
                workexpjobtitle: '',
                workexpduration: ''
            });
            setIeltsToeflScores({
                overall: '',
                listening: '',
                reading: '',
                writing: '',
                speaking: ''
            });
            setGregmatScores({
                overall: '',
                quantitative: '',
                verbal: '',
                awa: ''
            });
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setData({
            ...data
        })
    }, [data?.firstName, data?.lastName])

    return (
        <>
            <div className="logo">
                <a href="/">
                    <img
                        src="https://risehigher.education/logo.png"
                        className="img-fluid logo-sm m-4 ms-0"
                        alt="Rise Higher Education"
                        title="Rise Higher Education"
                    />
                </a>
            </div>
            <div className="page-title">
                In order to assist you better, we request you to fill out the profile form. Once completed, you can book a one-time free counseling session.
            </div>
            <div className="container profile-form">
                <div className="">
                    <form onSubmit={handleFormSubmit} method="POST">
                        <div className="row mb-3">
                            <div className="col form-floating">
                                <input
                                    name="firstName"
                                    onChange={onChangeInput}
                                    className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                    type="text"
                                    value={data.firstName}
                                    placeholder="First Name"
                                />
                                <label for="floatingInput">
                                    First Name<span className="required">*</span>
                                </label>
                                {errors.firstName && (
                                    <span className='required'>{errors.firstName}</span>
                                )}
                            </div>
                            <div className="col form-floating">
                                <input
                                    name="lastName"
                                    onChange={onChangeInput}
                                    className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                    type="text"
                                    value={data.lastName}
                                    placeholder="Last Name"
                                />
                                <label for="floatingInput">
                                    Last Name<span className="required">*</span>
                                </label>
                                {errors.lastName && (
                                    <span className='required'>{errors.lastName}</span>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-floating">
                                <input
                                    name="email"
                                    onChange={onChangeInput}
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    type="email"
                                    value={data.email}
                                    placeholder="Email"
                                />
                                <label for="floatingInput">
                                    Email<span className="required">*</span>
                                </label>
                                {errors.email && (
                                   <span className='required'>{errors.email}</span>
                                )}
                            </div>
                            <div className="col form-floating">
                                <input
                                    name="dateofbirth"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="date"
                                    value={data.dateofbirth}
                                    placeholder="Date of Birth"
                                    min="1970-01-01"
                                    max="2010-12-31"
                                />
                                <label for="floatingInput">
                                    Date of Birth
                                </label>
                            </div>
                        </div>
                        <hr />
                        <div className="row mb-3">
                            <div className="col-3 custom-formfloating">
                                <label for="floatingInput" className="ms-2">
                                    Country Code<span className="required">*</span>
                                </label>
                                <Select
                                    className="app-multiSelect"
                                    value={data.country_codes}
                                    options={country_codes.map((op, i) => ({
                                        value: op.dial_code,
                                        label: op.dial_code + ' ' + op.name,
                                    }))}
                                    onChange={(selectedOptions) => {
                                        //console.log('Select', selectedOptions)
                                        setData((prevData) => ({
                                            ...prevData,
                                            country_codes: selectedOptions,
                                        }))
                                    }}
                                    placeholder="Country Code"
                                // isOptionDisabled={() => credentials?.dest?.length >= 3}
                                />
                                {errors.country_codes && (
                                    <span className="required">{errors.country_codes}</span>
                                )}
                            </div>
                            <div className="col">
                                <label for="floatingInput" className="ms-2">
                                    Phone Number<span className="required">*</span>
                                </label>
                                <input
                                    name="phone"
                                    onChange={(e) => {
                                        if (String(e.target.value).length < 11) {
                                            onChangeInput(e)
                                        }
                                    }}
                                    className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                    type="number"
                                    maxLength={10}
                                    value={data.phone}
                                    placeholder="Phone Number"
                                />
                                {errors.phone && (
                                    <span className="required">{errors.phone}</span>
                                )}
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                            <label for="" className="ms-2">
                                    Study Destination<span className="required">*</span>
                                </label>
                            <Select
                                className="app-multiSelect"
                                isMulti
                                value={data.countryofinterest?.map((value) => ({
                                    value,
                                    label: value,
                                }))}
                                options={countryCodes.map((op, i) => ({
                                    value: op.name,
                                    label: op.name,
                                }))}
                                onChange={(selectedOptions) => {
                                    const newData = selectedOptions
                                    ? selectedOptions.map((option) => option.value)
                                    : [];
                                    setData((prevData) => ({
                                    ...prevData,
                                    countryofinterest: newData,
                                    }));
                                }}
                                placeholder="Choose Destination(s)"
                                isOptionDisabled={() => data?.countryofinterest?.length >= 3}
                                />
                                {errors.countryofinterest && (
                                    <span className="required">{errors.countryofinterest}</span>
                                )}
                            </div>
                            <div className="col form-floating">
                                <input
                                    name="courseofinterest"
                                    onChange={onChangeInput}
                                    className={`form-control ${errors.courseofinterest ? 'is-invalid' : ''}`}
                                    type="text"
                                    value={data.courseofinterest}
                                    placeholder="Course of Interest"
                                />
                                <label for="floatingInput">
                                    Course of Interest<span className="required">*</span>
                                </label>
                                {errors.courseofinterest && (
                                    <span className="required">{errors.courseofinterest}</span>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col custom-formfloating">
                                <label for="floatingInput" className="ms-2">
                                    Level<span className="required">*</span>
                                </label>
                                <select
                    className={`form-select ${errors.level ? 'is-invalid' : ''}`}
                    name="level"
                    value={data.level}
                    onChange={onChangeInput}
                    placeholder="Select level"
                  >
                    <option value="Select Level">Select Level</option>
                    <option value="English Language Program">
                      English Language Program
                    </option>
                    <option value="Foundation">Foundation</option>
                    <option value="Online Programmes / Distance Learning">
                      Online Programmes / Distance Learning
                    </option>
                    <option value="Pathway Programs">Pathway Programs</option>
                    <option value="Masters">Masters</option>
                    <option value="PG Diploma/Certificate">
                      PG Diploma/Certificate
                    </option>
                    <option value="PhD">PhD</option>
                    <option value="Short Term Programs">
                      Short Term Programs
                    </option>
                    <option value="Technical School/College">
                      Technical School/College
                    </option>
                    <option value="Trade School/College">
                      Trade School/College
                    </option>
                    <option value="Twinning Programmes (PG)">
                      Twinning Programmes (PG)
                    </option>
                    <option value="Bachelors">Bachelors</option>
                    <option value="UG Diploma/ Certificate/ Associate Degree">
                      UG Diploma/ Certificate/ Associate Degree
                    </option>
                  </select>
                  {errors.level && (
                                    <span className="required">{errors.level}</span>
                                )}
                            </div>
                            <div className="col form-floating">
                                <input
                                    name="spcialization"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="text"
                                    value={data.spcialization}
                                    placeholder="Spcialization / Concentration"
                                />
                                <label for="floatingInput">
                                    Spcialization / Concentration
                                </label>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col">
                                <p className="section-heading">
                                    10th / HSC
                                </p>
                                <div className="row">
                                    <div className="col form-floating">
                                        <input
                                            name="hscyearpassing"
                                            onChange={onChangeInput}
                                            className={`form-control ${errors.hscyearpassing ? 'is-invalid' : ''}`}
                                            type="number" min="1900" max="2099"
                                            value={data.hscyearpassing}
                                            placeholder="Year of Passing"
                                        />
                                        <label for="floatingInput">
                                            Year of Passing<span className="required">*</span>
                                        </label>
                                        {errors.hscyearpassing && (
                                    <span className="required">{errors.hscyearpassing}</span>
                                )}
                                    </div>
                                    <div className="col form-floating">
                                        <input
                                            name="hscgrade"
                                            onChange={onChangeInput}
                                            className={`form-control ${errors.hscgrade ? 'is-invalid' : ''}`}
                                            type="text"
                                            value={data.hscgrade}
                                            placeholder="Grades / %"
                                        />
                                        <label for="floatingInput">
                                            Grades / %<span className="required">*</span>
                                        </label>
                                        {errors.hscgrade && (
                                    <span className="required">{errors.hscgrade}</span>
                                )}
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <p className="section-heading">
                                    12th / SSC / Technical Diploma / IB
                                </p>
                                <div className="row">
                                    <div className="col form-floating">
                                        <input
                                            name="sscyearofpassing"
                                            onChange={onChangeInput}
                                            className="form-control mb-3"
                                            type="number" min="1900" max="2099"
                                            value={data.sscyearofpassing}
                                            placeholder="Year of Passing"
                                        />
                                        <label for="floatingInput">
                                            Year of Passing
                                        </label>
                                    </div>
                                    <div className="col form-floating">
                                        <input
                                            name="sscgrade"
                                            onChange={onChangeInput}
                                            className="form-control mb-3"
                                            type="text"
                                            value={data.sscgrade}
                                            placeholder="Grades / %"
                                        />
                                        <label for="floatingInput">
                                            Grades / %
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="section-heading">
                            Graduation
                        </p>
                        <div className="row">
                            <div className="col form-floating">
                                <input
                                    name="gradcourse"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="text"
                                    value={data.gradcourse}
                                    placeholder="Course / Specialization (if any)"
                                />
                                <label for="floatingInput">
                                    Course / Specialization (if any)
                                </label>
                            </div>
                            <div className="col form-floating">
                                <input
                                    name="graduniversity"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="text"
                                    value={data.graduniversity}
                                    placeholder="University"
                                />
                                <label for="floatingInput">
                                    University
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-floating">
                                <input
                                    name="gradcgpa"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="text"
                                    value={data.gradcgpa}
                                    placeholder="CGPA / % / Backlogs (if any)"
                                />
                                <label for="floatingInput">
                                    CGPA / % / Backlogs (if any) 
                                </label>
                            </div>
                            <div className="col form-floating">
                                <input
                                    name="gradduration"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="text"
                                    value={data.gradduration}
                                    placeholder="Duration"
                                />
                                <label for="floatingInput">
                                    Duration 
                                </label>
                            </div>
                        </div>
                        <p className="section-heading">
                            Post Graduation
                        </p>
                        <div className="row">
                            <div className="col form-floating">
                                <input
                                    name="postgradcourse"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="text"
                                    value={data.postgradcourse}
                                    placeholder="Course / Specialization (if any)"
                                />
                                <label for="floatingInput">
                                    Course / Specialization (if any)
                                </label>
                            </div>
                            <div className="col form-floating">
                                <input
                                    name="postgraduniversity"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="text"
                                    value={data.postgraduniversity}
                                    placeholder="University"
                                />
                                <label for="floatingInput">
                                    University
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-floating">
                                <input
                                    name="postgradcgpa"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="text"
                                    value={data.postgradcgpa}
                                    placeholder="CGPA / % / Backlogs (if any)"
                                />
                                <label for="floatingInput">
                                    CGPA / % / Backlogs (if any) 
                                </label>
                            </div>
                            <div className="col form-floating">
                                <input
                                    name="postgradduration"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="text"
                                    value={data.postgradduration}
                                    placeholder="Duration"
                                />
                                <label for="floatingInput">
                                    Duration 
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col'>
                                <p className="section-heading">
                                    IELTS / TOEFL
                                </p>
                                <div className="row">
            <div className="col form-floating">
              <input
                name="overall"
                onChange={handleIeltsToeflChange}
                className="form-control mb-3"
                type="text"
                value={ieltstoeflScores.overall}
                placeholder="Overall"
              />
              <label htmlFor="floatingInput">Overall</label>
            </div>
            <div className="col form-floating">
              <input
                name="listening"
                onChange={handleIeltsToeflChange}
                className="form-control mb-3"
                type="text"
                value={ieltstoeflScores.listening}
                placeholder="Listening"
              />
              <label htmlFor="floatingInput">Listening</label>
            </div>
            <div className="col form-floating">
              <input
                name="reading"
                onChange={handleIeltsToeflChange}
                className="form-control mb-3"
                type="text"
                value={ieltstoeflScores.reading}
                placeholder="Reading"
              />
              <label htmlFor="floatingInput">Reading</label>
            </div>
            <div className="col form-floating">
              <input
                name="writing"
                onChange={handleIeltsToeflChange}
                className="form-control mb-3"
                type="text"
                value={ieltstoeflScores.writing}
                placeholder="Writing"
              />
              <label htmlFor="floatingInput">Writing</label>
            </div>
            <div className="col form-floating">
              <input
                name="speaking"
                onChange={handleIeltsToeflChange}
                className="form-control mb-3"
                type="text"
                value={ieltstoeflScores.speaking}
                placeholder="Speaking"
              />
              <label htmlFor="floatingInput">Speaking</label>
            </div>
          </div>
                            </div>
                            </div>
                            <div className="row">
                            <div className='col'>
                                <p className="section-heading">
                                    GRE / GMAT
                                </p>
                                <div className="row">
            <div className="col form-floating">
              <input
                name="overall"
                onChange={handleGregmatChange}
                className="form-control mb-3"
                type="text"
                value={gregmatScores.overall}
                placeholder="Overall"
              />
              <label htmlFor="floatingInput">Overall</label>
            </div>
            <div className="col form-floating">
              <input
                name="quantitative"
                onChange={handleGregmatChange}
                className="form-control mb-3"
                type="text"
                value={gregmatScores.quantitative}
                placeholder="Quantitative"
              />
              <label htmlFor="floatingInput">Quantitative</label>
            </div>
            <div className="col form-floating">
              <input
                name="verbal"
                onChange={handleGregmatChange}
                className="form-control mb-3"
                type="text"
                value={gregmatScores.verbal}
                placeholder="Verbal"
              />
              <label htmlFor="floatingInput">Verbal</label>
            </div>
            <div className="col form-floating">
              <input
                name="awa"
                onChange={handleGregmatChange}
                className="form-control mb-3"
                type="text"
                value={gregmatScores.awa}
                placeholder="AWA"
              />
              <label htmlFor="floatingInput">AWA</label>
            </div>
          </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <p className="section-heading">
                                Internship (if any)
                            </p>
                            <div className="col form-floating">
                                <input
                                    name="internshipcompany"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="text"
                                    value={data.internshipcompany}
                                    placeholder="Company"
                                />
                                <label for="floatingInput">
                                    Company
                                </label>
                            </div>
                            <div className="col form-floating">
                                <input
                                    name="internshipduration"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="text"
                                    value={data.internshipduration}
                                    placeholder="Duration"
                                />
                                <label for="floatingInput">
                                    Duration
                                </label>
                            </div>
                            <div className="col form-floating">
                                <input
                                    name="internshipproject"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="text"
                                    value={data.internshipproject}
                                    placeholder="Projects / Publications"
                                />
                                <label for="floatingInput">
                                    Projects / Publications
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <p className="section-heading">
                                Work Experience (if any)
                            </p>
                            <div className="col form-floating">
                                <input
                                    name="workexpcompany"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="text"
                                    value={data.workexpcompany}
                                    placeholder="Company"
                                />
                                <label for="floatingInput">
                                    Company
                                </label>
                            </div>
                            <div className="col form-floating">
                                <input
                                    name="workexpjobtitle"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="text"
                                    value={data.workexpjobtitle}
                                    placeholder="Job Title"
                                />
                                <label for="floatingInput">
                                    Job Title
                                </label>
                            </div>
                            <div className="col form-floating">
                                <input
                                    name="workexpduration"
                                    onChange={onChangeInput}
                                    className="form-control mb-3"
                                    type="text"
                                    value={data.workexpduration}
                                    placeholder="Duration"
                                />
                                <label for="floatingInput">
                                    Duration
                                </label>
                            </div>
                        </div>
                        <div className="d-flex">
                            {loading ? (
                                <button
                                    className="btn btn-primary-regular"
                                    type="submit"
                                    disabled
                                >
                                    Loading...
                                </button>
                            ) : (
                                <input
                                    type="submit"
                                    value="Submit"
                                    className="btn btn-primary-regular"
                                />
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
