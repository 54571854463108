import React, { useState, useEffect } from "react";
import Testimonial from "./Testimonial";
import { url } from "../Constants";


const TestimonialSlider = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch(`${url}/testimonial/`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
          },
          mode: 'cors',
          referrerPolicy: "origin-when-cross-origin",
        });
        const data = await response.json();
        // console.log(data)
        setTestimonials(data);
      } catch (error) {
        //console.log(error);
      }
    };
    fetchTestimonials();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex(
        (currentIndex) => (currentIndex + 1) % testimonials.length
      );
    }, 2000);

    return () => clearInterval(intervalId);
  }, [testimonials]);

  // Validate testimonials array and set default value
  const testimonial =
    Array.isArray(testimonials) && testimonials[currentIndex]
      ? testimonials[currentIndex]
      : {};

  const handlePrevClick = () => {
    setCurrentIndex(
      currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex(
      currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1
    );
  };

  return (
    <div className="testimonial-slider">
      <Testimonial testimonial={testimonial} />
      <div className="testimonial-controls">
        <button onClick={handlePrevClick} className="testimonial-previous">
          <img src={require("../Assets/Media/Images/leftarrow_icon.png")} />
        </button>
        {/* {currentIndex + 1}/{testimonials.length} */}
        <button onClick={handleNextClick} className="testimonial-next">
          <img src={require("../Assets/Media/Images/rightarrow_icon.png")} />
        </button>
      </div>
    </div>
  );
};

export default TestimonialSlider;
