import React, { useEffect, useState, PureComponent } from "react";
import { url } from "../Constants";
import countryData from "../Assets/Data/countries.json";
import "../Assets/Styles/userDashboard.css";
import swal from "sweetalert";

import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";
import BarChartCustom from "./components/BarChart";
import { useNavigate } from "react-router-dom";
import Meter from "./components/Meter";
import { WorldChart } from "./components/WordChart";
import Calender from "../Calender/Calender";
import CountriwseComponent from "./components/CountriwseComponent";
import { steps } from "../Assets/Tutorials/UserDashboard";
import Joyride from "react-joyride";
import subsNavCheck from "../../utils/subscriptionNavCheck";
import subscriptionData from "../Assets/Data/subscription.json";

import { Button, Modal, Nav, Pagination } from "react-bootstrap";

import Select from 'react-select'
import country_codes from '../Assets/Data/countries_code.json'
import countryCodes from '../Assets/Data/countries.json'

import { validateReferralForm } from '../../utils/validation';


const UserDashboard = () => {
  // const chartdata = [

  //     {name: "CA", Scholarships: 1016},
  //     {name: "CY", Scholarships: 45},
  //     {name: "DK", Scholarships: 16},
  //     {name: "FI", Scholarships: 26},
  //     {name: "AU", Scholarships: 672},
  //     {name: "FR", Scholarships: 162},
  //     {name: "DE", Scholarships: 128},
  //     {name: "HU", Scholarships: 36},
  //     {name: "IN", Scholarships: 864},
  //     {name: "IE", Scholarships: 138},
  //     {name: "IT", Scholarships: 81},

  //     {name: "NL", Scholarships: 128},
  //     {name: "GB", Scholarships: 2004},
  //     {name: "NZ", Scholarships: 253},
  //     {name: "SG", Scholarships: 26},
  //     {name: "ES", Scholarships: 34},

  //     {name: "SE", Scholarships: 54},
  //     {name: "CH", Scholarships: 37},

  //     {name: "US", Scholarships: 1965}
  // ];

  const [chartdata, setChartdata] = useState([]);
  const [profileComplete, setProfileComplete] = useState(0);
  const [data, setData] = useState({
    firstName: "",
    email: "",
    CountryFlag: "",
    CountryName: "",
    capital: "",
    code: "",
    public_universities: "",
    private_universities: "",
    employment_rate: "",
    referStudyDest: [],
    country_codes: null,
  });

  const [completionSteps, setCompletionSteps] = useState({
    step2Complete: false,
    step3Complete: false,
    step4Complete: false,
    step5Complete: false,
    step6Complete: false,
    step7Complete: false,
  });
  const [profileMarkedComplete, setProfileMarkedComplete] = useState(false);

  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();

  const [weatherData, setWeatherData] = useState(null);
  const [isFahrenheit, setIsFahrenheit] = useState(true);

  const [randomPost, setRandomPost] = useState(null);

  const fetchRandomPost = async () => {
    try {
      const response = await fetch(
        "https://risehighereducation.com/wp-json/wp/v2/posts/",
        {
          method: "GET",
          mode: "cors",
          referrerPolicy: "origin-when-cross-origin",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const totalCount = response.headers.get("X-WP-Total");
      const randomPageNumber = Math.floor(Math.random() * totalCount) + 1;

      const pageResponse = await fetch(
        `https://risehighereducation.com/wp-json/wp/v2/posts/?per_page=1&page=${randomPageNumber}`,
        {
          method: "GET",
          mode: "cors",
          referrerPolicy: "origin-when-cross-origin",
        }
      );

      const data = await pageResponse.json();

      if (data.length > 0) {
        setRandomPost(data[0]); // Assuming you want the first post from the response
      }
    } catch (error) {
      console.error("Error fetching random post:", error);
    }
  };

  const removeImagesFromHtml = (html) => {
    // Use regular expression to remove <img> tags and their content
    return html.replace(/<img.*?>/g, "");
  };

  const convertToFahrenheit = (kelvin) => {
    const fahrenheit = ((kelvin - 273.15) * 9) / 5 + 32;
    return fahrenheit.toFixed(1); // Rounded to 2 decimal places
  };

  const convertToCelsius = (kelvin) => {
    const celsius = kelvin - 273.15;
    return celsius.toFixed(1); // Rounded to 2 decimal places
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const toggleTemperatureUnit = () => {
    // Toggle between Fahrenheit and Celsius
    setIsFahrenheit((prevIsFahrenheit) => !prevIsFahrenheit);
  };

  const getTemperature = () => {
    if (isFahrenheit) {
      return `${convertToFahrenheit(weatherData.main.temp)}° F`;
    } else {
      return `${convertToCelsius(weatherData.main.temp)}° C`;
    }
  };

  const getWeatherIconUrl = (iconCode) => {
    // Construct the URL for the weather icon based on the icon code
    return `https://openweathermap.org/img/w/${iconCode}.png`;
  };

  const getData = async () => {
    const response = await fetch(`${url}/auth/verifyuser`, {
      method: "POST",
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    });

    const res_json = await response.json();
    const json = res_json.data;

    localStorage.setItem(
      "courses",
      JSON.stringify(res_json?.userCourses?.userInfo?.course_of_interest)
    );
    localStorage.setItem("destination", JSON.stringify(json.studyDest));
    localStorage.setItem("level", res_json?.userCourses?.userInfo?.level);
    //console.log(json.studyDest);
    const contrData = await countryData.find((item) =>
      json.studyDest.includes(item.name)
    );
    // console.log(contrData);
    //console.log(res_json)
    //console.log(weatherres_json)

    const weatherResponse = await fetch(
      `https://api.openweathermap.org/data/2.5/weather?q=${contrData?.captial},${contrData?.code}&APPID=72c301bbae832a3f2b5aa7b5c91e47d7`,
      {
        method: "GET",
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
      }
    );

    const weatherres_json = await weatherResponse.json();
    if (weatherres_json.cod == "200") {
      setWeatherData(weatherres_json);
    }
    setData({
      ...data,
      firstName: json.firstName,
      email: json.email,
      studyDest: json.studyDest,
      CountryFlag: contrData?.CountryFlag,
      CountryName: contrData?.name,
      capital: contrData?.captial,
      code: contrData?.code,
      public_universities: contrData?.public_universities,
      private_universities: contrData?.private_universities,
      employment_rate: contrData?.employment_rate,
      lead_status: json?.lead_status,
      credits: json?.credits,
      studyDestination: countryData.find((item) =>
        json.studyDest.includes(item.name)
      ),
      country: countryData.find((item) =>
        json.countryCode.includes(item.dial_code)
      ),
    });
    setProfileComplete(res_json?.profileComplete);

    // Debugging logs
    //console.log("Profile Complete:", res_json?.profileComplete);
    //console.log("Profile Marked Complete in localStorage:", localStorage.getItem("profileMarkedComplete"));
    try {
      if (res_json?.profileComplete < 100) {
        setShowPopup(true);
        //console.log("Showing popup");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      console.error("Error details:", error.message);
    }

    //console.log(contrData)
  };

  const [latestDate, setLatestDate] = useState(new Date());

  useEffect(() => {
    const cData = countryData.map((item) => ({
      name: item.code,
      Scholarships: item.scholarshipCount,
    }));
    setChartdata(cData);

    const fetchLatestDate = () => {
      const currentDate = new Date();
      setLatestDate(currentDate);
    };

    fetchLatestDate();
  }, []);

  const formattedDate = latestDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  useEffect(() => {
    getData();

    fetchRandomPost();

    const intervalId = setInterval(() => {
      fetchRandomPost();
    }, 10000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleProfileComplete = () => {
  };

  useEffect(() => {
    // Fetch a new random post every time the randomPost state changes
    const updateRandomPost = setInterval(() => {
      fetchRandomPost();
    }, 5000); // 10000 milliseconds = 10 seconds

    return () => {
      clearInterval(updateRandomPost);
    };
  }, [randomPost]);

  const [appStatusCount, setAppStatusCount] = useState({});

  const getAppData = async () => {
    const response = await fetch(`${url}/application/count`, {
      method: "GET",
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    });

    const userResponse = await fetch(`${url}/user`, {
      method: 'GET',
      mode: 'cors',
      referrerPolicy: 'origin-when-cross-origin',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: localStorage.getItem('token'),
      },
    });

    const res_json = await response.json();
    const userJson = await userResponse.json();

    const completionStepsValue = userJson?.completionSteps || {
      step2Complete: false,
      step3Complete: false,
      step4Complete: false,
      step5Complete: false,
      step6Complete: false,
      step7Complete: false,
    };
    const profileMarkedCompleteValue = userJson?.profileMarkedComplete || false;

    setCompletionSteps(completionStepsValue);
    setProfileMarkedComplete(profileMarkedCompleteValue);

    //console.log('Completion Steps:', completionStepsValue);
    //console.log('Profile Marked Complete:', profileMarkedCompleteValue);

    const allStepsComplete = completionStepsValue.step2Complete && completionStepsValue.step3Complete && completionStepsValue.step4Complete && completionStepsValue.step5Complete && completionStepsValue.step6Complete && completionStepsValue.step7Complete;
    setShowPopup(!allStepsComplete);

    try {
      setAppStatusCount(res_json.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }

    setAppStatusCount(res_json.data);
  };

  const history = useNavigate();

  const logout = async () => {
    // if (
    //   localStorage.getItem("role") === "Admin" ||
    //   localStorage.getItem("role") === "Counsellor"
    // ) {
    //   history("/admin");
    // } else {
    //   history("/login");
    // }
    history("/login");
    localStorage.removeItem("token");
    localStorage.clear();
  };

  const [referFirstName, setReferFirstName] = useState('');
  const [referLastName, setReferLastName] = useState('');
  const [referEmail, setReferEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const refer = async (e) => {
    e.preventDefault(); 

    const referralData = {
      firstName: referFirstName,
      lastName: referLastName,
      email: referEmail,
      dest: data.referStudyDest,
      countryCode: data.country_codes?.value,
      phoneNumber: phoneNumber,
    };

    const { isValid, errors } = validateReferralForm({
      firstName: referFirstName,
      lastName: referLastName,
      email: referEmail,
      phoneNumber,
      country_codes: data.country_codes,
      referStudyDest: data.referStudyDest,
    });

    if (!isValid) {
      setErrors(errors);
      setLoading(false); 
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`${url}/referral/refer`, {  // Adjusted endpoint to `/refer`
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"), // Include token for authentication
        },
        body: JSON.stringify(referralData),  // Send referral data in request body
      });

      const res = await response.json();

      if (res.success) {
        swal({
          title: "Success",
          text: "Referral added successfully!",
          icon: "success",
          button: "Ok",
        });

        // Reset the form
        setReferFirstName("");
        setReferLastName("");
        setReferEmail("");
        setPhoneNumber("");
        setData({
          referStudyDest: [],
          country_codes: null,
        });
        setErrors({});
      } else {
        swal({
          title: "Error",
          text: res?.message || "Failed to add referral",
          icon: "error",
          button: "Ok",
        });
      }
    } catch (error) {
      swal({
        title: "Error",
        text: "An error occurred while adding the referral",
        icon: "error",
        button: "Ok",
      });
    }
    finally {
      setLoading(false);  // Reset loading state regardless of success/failure
    }
  };

  const getSubscriptionDetails = () => {
    const userSubId = localStorage.getItem("subscription_id");
    if (!userSubId || userSubId === "undefined") {
      return "Basic";
    }
    const plan = subscriptionData.find((itm) => itm.id === userSubId);
    return plan?.name;
  };

  const getLeadStatusDisplay = () => {
    const leadStatus = data?.lead_status;
    const subscriptionId = localStorage.getItem("subscription_id");

    if (leadStatus === "registeredplatinum" || subscriptionId === "platinum399plan") {
      return "Platinum";
    } else if (leadStatus === "registeredelite" || subscriptionId === "elite199plan") {
      return "Elite";
    } else if (leadStatus === "registeredpremium" || subscriptionId === "premium0plan") {
      return "Premium";
    } else {
      return "Basic";
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleCloseCredModal = () => {
    setShowModal(false);
  };

  const handleMarkComplete = async (step) => {
    const updates = {};
    updates[`step${step}Complete`] = true;

    try {
      const response = await fetch(`${url}/user/updateCompletionSteps`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          token: localStorage.getItem('token'),
        },
        body: JSON.stringify(updates),
      });

      const res_json = await response.json();

      if (res_json.success) {
        setCompletionSteps((prevSteps) => ({
          ...prevSteps,
          [`step${step}Complete`]: true,
        }));

        const allStepsComplete =
          profileComplete === 100 &&
          res_json.completionSteps.step2Complete &&
          res_json.completionSteps.step3Complete &&
          res_json.completionSteps.step4Complete &&
          res_json.completionSteps.step5Complete &&
          res_json.completionSteps.step6Complete &&
          res_json.completionSteps.step7Complete;

        if (allStepsComplete) {
          setShowPopup(false);
        }
      } else {
        throw new Error(res_json.message);
      }
    } catch (error) {
      console.error('Error updating completion step:', error);
    }
  };

  useEffect(() => {
    getAppData();
    handleMarkComplete();
  }, []);

  return (
    <>
      <div className="body-content no-leftnav">
        <Joyride
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
          hideBackButton={true}
          hideCloseButton={true}
          showSkipButton={true}
          showProgress={true}
          run={true}
          scrollDuration={300}
          continuous
          scrollOffset={{ x: 0, y: 20 }}
        />
        <div className="body-panel ms-4">
          <div className="body-container dashboard">
            {/* <CountriwseComponent
              studyDestination={data?.studyDestination}
              country={data?.country}
            /> */}
            <div className="d-flex justify-content-between">
              <h2>
                How Can We Help You <strong>RISE</strong> Today?
              </h2>
              <div
                className="d-flex justify-content-between text-end"
              >
                <p className="subsmall-heading">
                  {" "}
                  Current Plan: {getLeadStatusDisplay()}
                  {/* {getSubscriptionDetails()} */}
                  {/*{data.lead_status} */}
                  {getLeadStatusDisplay() !== "Platinum" && (
                    <a
                      className="ms-2"
                      href="/subscription-buy"
                    >
                      Upgrade
                    </a>
                  )}
                </p>
              </div>
            </div>
            <Modal show={showPopup} onHide={() => setShowPopup(false)} backdrop="static" keyboard={false} className="modal-lg">
              <Modal.Header className="steps-modal">
                <Modal.Title>Welcome {data.firstName} to Rise Higher Education</Modal.Title>
                <div className="d-flex align-items-center skip-steps">
                  <Button variant="primary-steps-link" onClick={() => setShowPopup(false)}>
                    Skip for Now
                  </Button>
                  <a href="" className="logout ms-1" onClick={logout}>
                    Logout
                  </a>
                </div>
              </Modal.Header>
              <Modal.Body>
                <ul className="vertical-steps">
                  <li className={profileComplete === 100 ? 'complete' : 'active'}>
                    <strong>Step 1: Profile Completion</strong>
                    <p>
                      Your profile is {profileComplete}% complete.{' '}
                      {profileComplete === 100 ? (
                        'Please proceed to the next steps.'
                      ) : (
                        <>
                          Please <a href="/profile" className="link-btn-sm p-1">complete your profile</a> to proceed to the next steps.
                        </>
                      )}
                    </p>
                  </li>
                  {profileComplete === 100 && (
                    <>
                      <li className={completionSteps.step2Complete ? 'complete' : 'active'}>
                        <strong>Step 2: Country Counseling</strong>
                        <p>Destination Discovery<br />
                          {!completionSteps.step2Complete ? (
                            <>
                              <a href="/counseling?modal=country-main" className="primary-steps-link">Start</a>
                              <Button variant="primary-steps" onClick={() => handleMarkComplete(2)}>
                                Skip
                              </Button>
                            </>
                          ) : (
                            <a href="/counseling?modal=country-main" className="primary-steps-link">Start</a>
                          )}</p>
                      </li>
                      <li className={completionSteps.step2Complete ? (completionSteps.step3Complete ? 'complete' : 'active') : ''}>
                        <strong>Step 3: Course Counseling</strong>
                        <p>Course Matchmaking<br />
                          {!completionSteps.step3Complete && completionSteps.step2Complete ? (
                            <>
                              <a href="/counseling?modal=course-main" className="primary-steps-link">Start</a>
                              <Button variant="primary-steps" onClick={() => handleMarkComplete(3)}>
                                Skip
                              </Button>
                            </>
                          ) : completionSteps.step2Complete ? (
                            <a href="/counseling?modal=course-main" className="primary-steps-link">Start</a>
                          ) : null}</p>
                      </li>
                      <li className={completionSteps.step3Complete ? (completionSteps.step4Complete ? 'complete' : 'active') : ''}>
                        <strong>Step 4: Schedule a Counseling</strong>
                        <br />
                        {!completionSteps.step5Complete && completionSteps.step4Complete ? (
                          <>
                            <Button variant="primary-steps">
                              Schedule a Counseling
                            </Button>
                            <Button variant="primary-steps ms-2" onClick={() => handleMarkComplete(5)}>
                              Mark as Complete
                            </Button>
                          </>
                        ) : completionSteps.step4Complete ? (
                          <Button variant="primary-steps">
                            Schedule a Counseling
                          </Button>
                        ) : null}
                      </li>
                      <li className={completionSteps.step4Complete ? (completionSteps.step5Complete ? 'complete' : 'active') : ''}>
                        <strong>Step 5: Shortlisting</strong>
                        <p>Shortlist your programs, Your way!<br />
                          {!completionSteps.step4Complete && completionSteps.step3Complete ? (
                            <>
                              <button
                                onClick={() => {
                                  if (getLeadStatusDisplay() === "Platinum") {
                                    navigate("/shortlisting");
                                  } else {
                                    navigate("/CourseSelection");
                                  }
                                }}
                                className="primary-steps-link"
                              >
                                Start
                              </button>
                              <Button variant="primary-steps" onClick={() => handleMarkComplete(4)}>
                                Skip
                              </Button>
                            </>
                          ) : completionSteps.step3Complete ? (
                            <button
                              onClick={() => {
                                if (getLeadStatusDisplay() === "Platinum") {
                                  navigate("/shortlisting");
                                } else {
                                  navigate("/CourseSelection");
                                }
                              }}
                              className="primary-steps-link"
                            >
                              Start
                            </button>
                          ) : null}</p>
                      </li>
                      <li className={completionSteps.step5Complete ? (completionSteps.step6Complete ? 'complete' : 'active') : ''}>
                        <strong>Step 6: Upload Documents</strong>
                        <p>Headstart with Document Preparation<br />
                          {!completionSteps.step6Complete && completionSteps.step5Complete ? (
                            <>
                              <a href="/documents" className="primary-steps-link">Start</a>
                              <Button variant="primary-steps" onClick={() => handleMarkComplete(6)}>
                                Skip
                              </Button>
                            </>
                          ) : completionSteps.step5Complete ? (
                            <a href="/documents" className="primary-steps-link">Start</a>
                          ) : null}</p>
                      </li>
                      <li className={completionSteps.step6Complete ? (completionSteps.step7Complete ? 'complete' : 'active') : ''}>
                        <strong>Step 7: Application Dashboard</strong>
                        <p>Start Your Error-Free Application Journey<br />
                          {!completionSteps.step7Complete && completionSteps.step6Complete ? (
                            <>
                              <a href="/applicationdashboard" className="primary-steps-link">Start</a>
                              <Button variant="primary-steps" onClick={() => handleMarkComplete(7)}>
                                Skip
                              </Button>
                              <Button variant="primary-steps ms-2" onClick={() => handleMarkComplete(7)}>
                                Mark as Complete
                              </Button>
                            </>
                          ) : completionSteps.step6Complete ? (
                            <a href="/applicationdashboard" className="primary-steps-link">Start</a>
                          ) : null}</p>
                      </li>
                    </>
                  )}
                </ul>
              </Modal.Body>
            </Modal>
            <div className="row">
              <div className="col-9 dash-block">
                <div className="col d-flex dash-col">
                  <div className="dashboard-widget-frame counseling-tile ">
                    <div className="widget-title">Counseling</div>
                    <div className="d-flex">
                      <div>
                        <p className="widget-subtitle">
                          Complete Counseling Companion
                          {/* <span className="coming-soon">coming soon</span> */}
                          <ul className="counseling-list">
                            <li
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate("/counseling?modal=country-main");
                              }}
                            >
                              <span>Destination Discovery</span>
                              <img
                                className="country"
                                title="Destination Discovery"
                                src={require("../Assets/Media/Images/country_counseling.png")}
                              />
                            </li>
                            <li
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate("/counseling?modal=course-main");
                              }}
                            >
                              <span>Course Matchmaker</span>
                              <img
                                className="course"
                                title="Course Matchmaker"
                                src={require("../Assets/Media/Images/course_counseling.png")}
                              />
                            </li>
                          </ul>
                        </p>
                        <div className="clearfix"></div>
                        <button
                          className="widget-cta btn-counseling"
                          onClick={() => {
                            // if (subsNavCheck("/counseling"))
                            navigate("/counseling");
                          }}
                        >
                          Start Counseling
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="arrow">
                    <div className="arrow-top" />
                    <div className="arrow-bottom" />
                  </div>
                  <div className="dashboard-widget-frame shortlisting-tile">
                    <p className="widget-title">Shortlisting</p>
                    <div className="d-flex">
                      <div className="">
                        <p className="widget-subtitle">
                          Shortlist your programs,
                          <br />
                          Your way!
                        </p>
                        <div className="d-flex justify-content-between">
                          <div className="text-end">
                            <img
                              className="shortlisting"
                              title="Shortlist your programs, Your way!"
                              src={require("../Assets/Media/Images/shortlisting.png")}
                            />
                          </div>
                        </div>
                        <div className="button-alignment">
                          {/* */}
                          <button
                            onClick={() => {
                              if (getLeadStatusDisplay() === "Platinum") {
                                navigate("/shortlisting");
                              } else {
                                navigate("/CourseSelection");
                              }
                            }}
                            className="widget-cta"
                          >
                            Shortlist Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="arrow">
                    <div className="arrow-top" />
                    <div className="arrow-bottom" />
                  </div>
                  <div className="dashboard-widget-frame document-tile">
                    <p className="widget-title">Document Checklist</p>
                    <div className="d-flex">
                      <div className="">
                        <p className="widget-subtitle">
                          Headstart with Document Preparation
                        </p>
                        <div className="button-alignment">
                          <button
                            className="widget-cta"
                            onClick={() => {
                              navigate("/documents");
                            }}
                          >
                            Upload Documents
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col d-flex dash-col">
                  <div className="dashboard-widget-frame application-tile">
                    <p className="widget-title">Application Dashboard</p>
                    <div className="d-flex">
                      <div className="">
                        <p className="widget-subtitle">
                          Start Your Error-Free Application Journey
                        </p>
                        <BarChartCustom data={appStatusCount} />
                        {/* <img
                          className="application"
                          title="View Applications Status"
                          src={require("../Assets/Media/Images/application-dashboard.png")}
                        /> */}
                        <ul className="application-status">
                          {Object?.keys(appStatusCount).map((item, idx) =>
                            appStatusCount[item] > 0 ? (
                              <li key={idx}>
                                {/* <span
                                  // className={`${item.split(" ")[0]}`}
                                  title={item}
                                ></span> */}
                                <p>
                                  <strong>{item}:</strong> {appStatusCount[item]}
                                </p>
                              </li>
                            ) : null
                          )}
                          {Object?.keys(appStatusCount).every(
                            (item) => appStatusCount[item] <= 0
                          ) && (
                              <li className="no-application">
                                No applications in process
                              </li>
                            )}
                        </ul>
                        <div className="button-alignment">
                          <button
                            className="widget-cta"
                            onClick={() => {
                              navigate("/applicationdashboard");
                            }}
                          >
                            View Applications Status
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="arrow arrow-left">
                    <div className="arrow-top" />
                    <div className="arrow-bottom" />
                  </div>
                  <div className="dashboard-widget-frame">
                    <p className="widget-title">Scholarships</p>
                    <p className="widget-subtitle">Scholarships made simple!</p>
                    <WorldChart />
                    <div className="d-flex">
                      <div className="">
                        {/* <BarChart
                          width={235}
                          height={150}
                          data={chartdata}
                          margin={{
                            top: 5,
                            right: 0,
                            left: 0,
                            bottom: 5,
                          }}
                        >
                          <XAxis dataKey="name" tick={{ fontSize: "8px" }} />
                          <Tooltip />
                          <Bar dataKey="Scholarships" fill="#f37a27" />
                        </BarChart> */}
                        <button
                          className="widget-cta"
                          onClick={() => {
                            window.location.href = `/scholarship`;
                          }}
                        >
                          Explore Scholarships
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="arrow arrow-left">
                    <div className="arrow-top" />
                    <div className="arrow-bottom" />
                  </div>
                  <div className="dashboard-widget-frame coaching-tile">
                    <p className="widget-title">Coaching</p>
                    <div className="d-flex">
                      <div className="">
                        <p className="widget-subtitle">
                          Get to know which test is applicable for you.
                        </p>
                        <div className="button-alignment">
                          <button
                            className="widget-cta"
                            onClick={() => {
                              navigate("/assessment");
                            }}
                          >
                            Search Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col d-flex dash-col">
                  <div className="dashboard-widget-frame dashboard-widget-frame-2x predeparture-tile">
                    <p className="widget-title">Pre Departure</p>
                    <div className="d-flex">
                      <div className="dashboard-tile-category dashboard-tile-category-multi">
                        <ul>
                          <li>
                            Accommodation
                            <p>Your Home Away from Home</p>
                            <div className="button-alignment-noPos">
                              <button
                                className="widget-cta"
                                onClick={() => {
                                  navigate("/accommodation");
                                }}
                              >
                                Search Now
                              </button>
                            </div>
                          </li>
                          <li>
                            Flights
                            <p>Soar to Your Dreams</p>
                            <div className="button-alignment-noPos">
                              <button
                                className="widget-cta"
                                onClick={() => {
                                  navigate("/flights");
                                }}
                              >
                                Book Now
                              </button>
                            </div>
                          </li>
                          <li>
                            Student Loans
                            <p>Compare and Choose Loans</p>
                            <div className="button-alignment-noPos">
                              <button
                                className="widget-cta"
                                onClick={() => {
                                  navigate("/loans");
                                }}
                              >
                                Search Now
                              </button>
                            </div>
                          </li>
                          <li>
                            Currency Exchange
                            <p>Get the Best Rate Abroad</p>
                            <div className="button-alignment-noPos">
                              <button
                                className="widget-cta"
                                onClick={() => {
                                  navigate("/currencyexchange");
                                }}
                              >
                                Get Rates
                              </button>
                            </div>
                          </li>
                          <li>
                            SIM Card
                            <p>Worry Free Travel</p>
                            <div className="button-alignment-noPos">
                              <button
                                className="widget-cta"
                                onClick={() => {
                                  navigate("/simcard");
                                }}
                              >
                                Get SIM
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="arrow">
                    <div className="arrow-top" />
                    <div className="arrow-bottom" />
                  </div>
                  <div className="dashboard-widget-frame postdeparture-tile">
                    <p className="widget-title">Post Departure</p>
                    <div className="d-flex">
                      <div className="dashboard-tile-category">
                        <ul>
                          <li>
                            Jobs
                            <p>Part Time/Full Time Jobs: We got it Covered</p>
                            <div className="button-alignment">
                              <button
                                className="widget-cta"
                                onClick={() => {
                                  navigate("/recruitment");
                                }}
                              >
                                Apply Now
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col d-flex dash-col">
                  <div className="dashboard-widget-frame dashboard-widget-frame-3x orange-widget">
                    Unveil your best-kept secret. Refer RISE to academic aspirants
                    among your friends and family, and earn commission on all registered students.
                    {/* <br />
                    {localStorage.getItem("isAffiliateUser") !== "true" ? (
                      <a className="btn btn-nostyle btn-nostyle-white" href="/affiliate">
                        Start Affiliate Program
                      </a>
                    ) : (
                      <a
                        className="btn btn-nostyle btn-nostyle-white"
                        href={`/affiliate-dashboard/${localStorage.getItem(
                          "affiliate_id"
                        )}`}
                      >
                        View Affiliate Dashboard{" "}
                      </a>
                    )} */}
                    <form onSubmit={refer}>
                      <div className="referral-form">
                        <div className="row">
                          <div className="col form-floating mt-3">
                            <input
                              type="text"
                              className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                              value={referFirstName}
                              onChange={(e) => setReferFirstName(e.target.value)}
                              placeholder="Referral First Name:"
                            />
                            <label htmlFor="">Referral First Name<span className="required">*</span></label>
                            {errors.firstName && (
                              <span className="required label-white">{errors.firstName}</span>
                            )}
                          </div>
                          <div className="col form-floating mt-3">
                            <input
                              type="text"
                              className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                              value={referLastName}
                              onChange={(e) => setReferLastName(e.target.value)}
                              placeholder="Referral Last Name:"
                            />
                            <label htmlFor="">Referral Last Name<span className="required">*</span></label>
                            {errors.lastName && (
                              <span className="required label-white">{errors.lastName}</span>
                            )}
                          </div>
                          <div className="col form-floating mt-3">
                            <input
                              type="text"
                              className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                              value={referEmail}
                              onChange={(e) => {
                                setReferEmail(e.target.value);
                              }}
                              placeholder="Referral Email"
                            />
                            <label htmlFor="">Referral Email<span className="required">*</span></label>
                            {errors.email && (
                              <span className="required label-white">{errors.email}</span>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col mt-2">
                            <label className="label-white" htmlFor="">Country Code<span className="required">*</span></label>
                            <Select
                              className="app-multiSelect"
                              value={data.country_codes} // Make sure this holds the correct object {value, label}
                              options={country_codes.map((op) => ({
                                value: op.dial_code,
                                label: `${op.dial_code} ${op.name}`,
                              }))}
                              onChange={(selectedOption) => {
                                setData((prevData) => ({
                                  ...prevData,
                                  country_codes: selectedOption, // This will store the selected object with {value, label}
                                }));
                              }}
                              placeholder="Country Code"
                            />
                            {errors.countryCode && (
                              <span className="required label-white">{errors.countryCode}</span>
                            )}
                          </div>
                          <div className="col form-floating mt-3">
                            <input
                              type="text"
                              className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                              value={phoneNumber}
                              onChange={(e) => {
                                if (e.target.value.length <= 10) {  // Limiting the length to 10
                                  setPhoneNumber(e.target.value);   // Update the state only if length is <= 10
                                }
                              }}
                              placeholder="Phone Number"
                            />
                            <label htmlFor="">Phone Number<span className="required">*</span></label>
                            {errors.phone && (
                              <span className="required label-white">{errors.phone}</span>
                            )}
                          </div>
                          <div className="col mt-2">
                            <label className="label-white" htmlFor="">Study Destination<span className="required">*</span></label>
                            <Select
                              className="app-multiSelect"
                              isMulti // Allows multiple selections
                              value={data.referStudyDest?.map((value) => ({
                                value, // Ensure this is the actual value for the destination
                                label: value, // Ensure this is the display label for the destination
                              }))}
                              options={countryCodes.map((op) => ({
                                value: op.name, // Country name as value
                                label: op.name, // Country name as label
                              }))}
                              onChange={(selectedOptions) => {
                                const newDestinations = selectedOptions
                                  ? selectedOptions.map((option) => option.value) // Extract only the value (country name)
                                  : [];
                                setData((prevData) => ({
                                  ...prevData,
                                  referStudyDest: newDestinations, // Update the selected destinations
                                }));
                              }}
                              placeholder="Choose Destination(s)"
                              isOptionDisabled={() => data?.referStudyDest?.length >= 3} // Limit selection to 3 destinations
                            />
                            {errors.referStudyDest && (
                              <span className="required label-white">{errors.referStudyDest}</span>
                            )}
                          </div>
                        </div>
                        <button type="submit" className="btn btn-cta-lg mt-2" disabled={loading}>
                          {loading ? 'Submitting...' : 'Refer'}
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* <div className="dashboard-widget-frame affiliate-tile">
                  <p className="widget-title">Affiliate Dashboard</p>
                  <p className="widget-subtitle">
                    Refer your friends, and earn commission on all registered students.
                  </p>
                  
                  </div> */}
                </div>
              </div>
              <div className="col-3 dash-block dashboard-rightpanel">
                {/* <div className="dashboard-widget-frame dashboard-widget-frame-nozoom">
                  <div className="">
                    <p className="widget-title">Total Credits Available: {data?.lead_status} </p>
                  </div>
                </div> */}
                <div className="dashboard-widget-frame dashboard-widget-frame-nozoom">
                  <div className="">
                    <p className="widget-title">Profile Completion</p>
                    <Meter percentage={profileComplete} />
                    {/* Display the overall completion percentage */}
                    {/* <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${profileComplete}%` }}
                        aria-valuenow={profileComplete}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {profileComplete}%
                      </div>
                    </div> */}
                    {/* <div id="progressbar">
                      <div style={{ width: "40%" }}></div>
                    </div> */}
                  </div>
                </div>
                <div className="dashboard-widget-frame dashboard-widget-frame-nozoom">
                  <p className="widget-title">Activity Timeline</p>
                  <Calender />
                </div>
                {/*
                <section className="section dashboard-widget-frame d-flex justify-content-between">
                  <div className="page-content">
                    <span className="user-fletter-avatar">
                      {data.firstName.charAt(0)}
                    </span>
                    <h1 className="user-name">Welcome, {data.firstName}</h1>
                  </div>
                </section> */}
                <div className="dashboard-widget-frame weather-widget">
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="widget-title">{data?.CountryName}</p>
                      <p>
                        <img src={data?.CountryFlag} width={40} height={40} />
                      </p>
                      <p>{data?.capital}</p>
                    </div>
                    <div className="weather-info">
                      <span className="todays-date">{formattedDate}</span>
                      {weatherData ? (
                        <p>
                          <img
                            src={getWeatherIconUrl(weatherData.weather[0].icon)}
                            width={32}
                            height={32}
                            alt={weatherData.weather[0].description}
                          />
                          <button
                            onClick={toggleTemperatureUnit}
                            title="click to change"
                            className="btn nostyle weather-temp"
                          >
                            {getTemperature()}
                          </button>
                          ,{" "}
                          {capitalizeFirstLetter(
                            weatherData.weather[0].description
                          )}
                        </p>
                      ) : (
                        <p>Loading weather data...</p>
                      )}
                    </div>
                  </div>
                  <p>
                    <strong>{data?.public_universities}</strong> Public &{" "}
                    <strong>{data?.private_universities}</strong> Private
                    Universities
                  </p>
                  <p>
                    Graduate Employment Rate:{" "}
                    <strong>{data?.employment_rate}%</strong>
                  </p>
                  <hr className="hr-sep" />
                  <p className="widget-title mt-2 mb-2">Latest News</p>
                  {randomPost && (
                    <div>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: randomPost.title.rendered,
                        }}
                      />
                      <p className="mb-2"></p>
                      {/* Display only the first 500 words of the post content without images */}
                      {/* <div
                          dangerouslySetInnerHTML={{
                              __html: `${removeImagesFromHtml(
                                  randomPost.content.rendered
                              )
                                  .split(' ')
                                  .slice(0, 20)
                                  .join(' ')}${randomPost.content.rendered.length > 20 ? '...' : ''}`,
                          }}
                      /> */}
                      {/* Add a "Read More" link */}
                      {randomPost.content.rendered.length > 20 && (
                        <a href={randomPost.link} target="_blank">
                          Read More
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserDashboard;
