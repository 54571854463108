import React, { useEffect, useState } from 'react'

const PassportInfo = (props) => {

    const [data, setData] = useState({
        passportNumber: "",
        issueDate: "",
        expiryDate: "",
        issueCountry: "",
        cityOfBirth: "",
        countryOfBirth: "",
        citizenship: "",
        nationality: "",
        multiCitizenship: null,
        liveAndStudyOtherCountry: null,
    })

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (props.data !== null && props.data !== undefined) {
            setData(props.data)
        }
    }, [props.data])

    return (
        <div className="tab-pane fade" id="v-pills-messages" role="tabpanel"
            aria-labelledby="v-pills-messages-tab">
            <div className="row profile-section">
                <div className="col">
                    <div className="section-head">Passport Information</div>
                    <div className="form-group section-content">
                        <div className="row mt-3 mb-3 responsive-col">
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="passportNumber" value={data.passportNumber} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="Passport No."
                                        aria-label="" />
                                    <label htmlFor="">Passport No.</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="date" name="issueDate" value={data.issueDate} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="Issue Date"
                                        aria-label="" />
                                    <label htmlFor="">Issue Date</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="date" name="expiryDate" value={data.expiryDate} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="Expiry Date"
                                        aria-label="" />
                                    <label htmlFor="">Expiry Date</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3 mb-3 responsive-col">
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="issueCountry" value={data.issueCountry} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="Issue Country"
                                        aria-label="" />
                                    <label htmlFor="">Issue Country</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="cityOfBirth" value={data.cityOfBirth} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="City of Birth"
                                        aria-label="" />
                                    <label htmlFor="">City of Birth</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="countryOfBirth" value={data.countryOfBirth} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="Country of Birth"
                                        aria-label="" />
                                    <label htmlFor="">Country of Birth</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="citizenship" value={data.citizenship} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="Citizenship"
                                        aria-label="" />
                                    <label htmlFor="">Citizenship</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input type="text" name="nationality" value={data.nationality} onChange={onChange} className="form-control"
                                        defaultValue="" placeholder="Nationality"
                                        aria-label="" />
                                    <label htmlFor="">Nationality</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3 mb-3 responsive-col">
                            <div className="col">
                                <label htmlFor="">
                                    Is the applicant a citizen of more than one country?
                                    <span className="required">*</span>
                                </label>
                                <br />
                                <input
                                    type="radio"
                                    className="btn-check"
                                    checked={data.multiCitizenship === false}
                                    onClick={() => { setData({ ...data, multiCitizenship: false }) }}
                                    name="option2"
                                    id="option21"
                                />
                                <label className="btn btn-secondary" htmlFor="option21">
                                    No
                                </label>
                                <input
                                    type="radio"
                                    className="btn-check"
                                    checked={data.multiCitizenship === true}
                                    onClick={() => { setData({ ...data, multiCitizenship: true }) }}
                                    name="option2"
                                    id="option22"
                                />
                                <label className="btn btn-secondary" htmlFor="option22">
                                    Yes
                                </label>
                            </div>
                            <div className="col">
                                <label htmlFor="">
                                    Is the applicant living and studying in any other country?
                                    <span className="required">*</span>
                                </label>
                                <br />
                                <input type="radio" className="btn-check" checked={data.liveAndStudyOtherCountry === false} onClick={() => { setData({ ...data, liveAndStudyOtherCountry: false }) }} name="liveAndStudyOtherCountry" id="option23"
                                    autoComplete="off" defaultChecked="" />
                                <label className="btn btn-secondary" htmlFor="option23">
                                    No
                                </label>
                                <input type="radio" className="btn-check" checked={data.liveAndStudyOtherCountry === true} onClick={() => {
                                    //console.log("Here")
                                    setData({ ...data, liveAndStudyOtherCountry: true })
                                }} name="liveAndStudyOtherCountry" id="option24"
                                    autoComplete="off" />
                                <label className="btn btn-secondary" htmlFor="option24">
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div className="row responsive-col">
                            <div className="col text-end action-btn-group">
                                <button type="submit" className="btn btn-secondary">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary-regular" onClick={() => { props.updateData("passport", data) }}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PassportInfo
